import { render, staticRenderFns } from "./k-mobile-nav.vue?vue&type=template&id=40bee1c2&"
import script from "./k-mobile-nav.vue?vue&type=script&lang=js&"
export * from "./k-mobile-nav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KIcon from '@web/components/core/k-icon'
import KLanguageNavSwitcher from '@web/components/core/k-language-nav-switcher'
import KNavUser from '@web/components/core/k-nav-user'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {KIcon,KLanguageNavSwitcher,KNavUser,VBtn,VNavigationDrawer})
